<template>
  <div
    id="infosperso"
    class="container"
  >
    <div class="row">
      <h2>{{ $t('account.title') }}</h2>
    </div>
    <div class="tabs row">
      <div
        id="1"
        class="tab-item col-5 col-md-3"
        @click="tab = 'auth'"
      >
        {{ $t('account.auth') }}
      </div>
      <div
        id="2"
        class="tab-item col-5 col-md-3"
        @click="tab = 'coords'"
      >
        {{ $t('account.invoiceAddress') }}
      </div>
      <div
        id="3"
        class="tab-item col-5 col-md-3"
        @click="tab = 'delivery'"
      >
        {{ $t('account.deliveryAddress') }}
      </div>
    </div>
    <div class="tab-contents">
      <div
        id="1"
        :class="{ active: tab === 'auth' }"
        class="content"
      >
        <InfosPersoAuth />
      </div>

      <div
        id="2"
        :class="{ active: tab === 'coords' }"
        class="content"
      >
        <InfosPersoAdresse />
      </div>

      <div
        id="3"
        :class="{ active: tab === 'delivery' }"
        class="content"
      >
        <InfosPersoAdresseLivraison />
      </div>
    </div>
  </div>
</template>

<script>
import InfosPersoAuth from './InfosPersoAuth'
import InfosPersoAdresse from './InfosPersoAdresse'
import InfosPersoAdresseLivraison from './InfosPersoAdresseLivraison'

export default {
  name: 'InfosPerso',
  components: {
    InfosPersoAuth,
    InfosPersoAdresse,
    InfosPersoAdresseLivraison
  },
  data: () => ({
    tab: 'auth'
  })
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#infosperso {
  padding-top: 50px;

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    width: 70%;
  }

  h2 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      padding-bottom: 40px;
    }
  }
  .tabs {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      padding-left: 10%;
      justify-content: flex-start;
    }
      .tab-item {
        color: white;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 0px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          padding: 10px 20px;
          font-size: 18px;
        }
      }
      .tab-item[id="1"] {
        background-color: var(--dark-yellow-color);
      }
      .tab-item[id="2"] {
        background-color: #BA8DC0;
      }
      .tab-item[id="3"] {
        background-color: #ccc;
      }
  }

  .tab-contents > div[id="1"] {
    background-color: var(--dark-yellow-color);
  }
  .tab-contents > div[id="2"] {
    background-color: #BA8DC0;
  }
  .tab-contents > div[id="3"] {
    background-color: #ccc;
  }

  .tab-contents > div[id="1"] button {
    background-color: #BA8DC0;
  }
  .tab-contents > div[id="2"] button {
    background-color: var(--dark-yellow-color) !important;
  }
  .tab-contents > div[id="3"] button {
    background-color: #ccc;
  }

  .tab-contents {
      .content {
        padding: 40px 20px;
        margin: 0px 5px;
        text-align: left;
        color: white;

        @include media-breakpoint-up(md) {
          padding: 80px 100px;
        }

        label {
          font-size: 16px;
          font-weight: 700;

          @include media-breakpoint-up(md) {
            font-size: 18px;
          }
        }

        input:not(.password-input) {
          width: 100%;
          height: 36px;
        }

        input {
          box-sizing: border-box;
          border: none;
          box-shadow: inset 1px 1px 3px #0000009a;
          padding: 10px;
        }

        .right-padding {
          @include media-breakpoint-down(md) {
            padding-right: 5px;
          }
        }
        .left-padding {
          @include media-breakpoint-down(md) {
            padding-left: 5px;
          }
        }
      }
  }
  form input:focus-visible {
    outline: transparent;
  }

  .submit-button {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }

    button {
      background-color: #BA8DC0;
      border: none;
      padding: 10px 20px;
      border-radius: 10px;
      color: white;
      font-size: 18px;
      font-weight: 700;
      box-shadow: 1px 1px 5px #00000087;
    }
  }

  .tab-contents > div:not(.active ) {
    display: none;
  }
}
</style>
