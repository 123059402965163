<template>
  <div id="monespaceperso">
    <BigText
      color="white"
      bgcolor="yellow"
      :title="t1 || ''"
      position="left"
      class="d-xl-none"
    />
    <BigText
      color="white"
      bgcolor="yellow"
      :title="t2 || ''"
      position="left"
      class="d-none d-xl-flex"
    />
    <div class="text-bg-espace">
      <BigText
        color="white"
        bgcolor="yellow"
        :title="t3 || ''"
        position="left"
        class="d-xl-none"
      />
    </div>
    <div class="text-bg-perso">
      <BigText
        color="white"
        bgcolor="yellow"
        :title="t4 || ''"
        position="left"
      />
    </div>
    <img src="@/assets/user-big-icon.png">
  </div>
</template>

<script>
import BigText from '@/components/UI/BigText.vue'

export default {
  name: 'MonEspacePerso',
  components: {
    BigText
  },
  data: () => ({
    text: null
  }),
  computed: {
    t1() {
      // MON
      return this.text?.length && this.text[0]
    },
    t2() {
      // MON ESPACE
      return this.text?.length > 1 && `${this.text[0]} ${this.text[1]}`
    },
    t3() {
      // ESPACE
      return this.text?.length > 1 && this.text[1]
    },
    t4() {
      // PERSO
      return this.text?.slice().pop()
    }
  },
  mounted() {
    // MON ESPACE PERSO
    this.text = this.$i18n.t('account.personalSpace').split(' ')
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.text-bg-espace h2{
  top:60px;
  @include media-breakpoint-up(md) {
    top:90px;
  }
  @include media-breakpoint-up(lg) {
    top:120px;
  }
}
.text-bg-perso h2{
  top:120px;
  @include media-breakpoint-up(md) {
    top:180px;
  }
  @include media-breakpoint-up(lg) {
    top:240px;
  }
  @include media-breakpoint-up(xl) {
    top:130px;
  }
}

#monespaceperso {
  background-color: var(--dark-yellow-color);
  height: 315px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include media-breakpoint-up(md) {
    height: 390px;
  }
}
h2 {
  max-width: 70%;
  left: -2px;
  padding-top: 30px;
}
img {
  position: absolute;
  bottom: -55px;
  margin-right: 10%;
  max-width: 150px;
  max-height: 150px;

  @include media-breakpoint-up(md) {
    position: relative;
    max-width: 200px;
    max-height: 200px;
  }
}
</style>
