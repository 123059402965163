<template>
  <div class="commande-item">
    <div class="col-12 col-md-4 d-flex flex-direction-row pb-3 p-md-0">
      <div><img src="@/assets/minicart-icon-white.svg"></div>
      <div><span>{{ orderDate | date('LLLL') }}</span></div>
    </div>
    <div class="col-6 col-md-2 text-center">
      <span class="amount">{{ total | price('light') }}</span>
    </div>
    <div class="col-3 col-md-3 text-center">
      <a
        :href="orderUrl"
        data-bs-toggle="tooltip"
        :title="$t('orders.orderPdf')"
        target="_blank"
      >
        <img src="@/assets/download-icon.png">
      </a>
    </div>
    <div class="col-3 col-md-3 text-center">
      <a
        v-if="invoiceUrl"
        :href="invoiceUrl"
        data-bs-toggle="tooltip"
        :title="$t('orders.invoice')"
        target="_blank"
      >
        <img src="@/assets/download-icon.png">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DernieresCommandesItem',
  props: {
    order: { type: Object }
  },
  computed: {
    orderDate() {
      return this.order.delivery?.date || this.order.metadata.created_at
    },

    orderUrl() {
      return `${this.$config.api}/customerorders/${this.order.id}`
    },

    total() {
      if (!this.order.invoice?.capture?.amount) {
        return this.order.total_ordered
      }
      return this.order.invoice.capture.amount
    },

    invoiceUrl() {
      if (!this.order.invoice) {
        return null
      }
      return `${this.$config.api}/customerinvoices/${this.order.invoice.id}`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.commande-item {
  padding: 10px 0;

  .amount {
    font-weight: 400 !important;
  }

  & > div {
    font-weight: 700;
    font-size: 16px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  img {
    max-width: 25px;
    margin: 0 10px;
    @include media-breakpoint-up(md) {
      max-width: 30px;
      margin: 0 20px;
    }
  }
}
</style>
