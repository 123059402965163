<template>
  <div id="dernierescommandes">
    <div class="container">
      <div class="row title">
        <div class="col-12 offset-lg-2 col-lg-8">
          <h2>
            <div class="cart-icons">
              <img src="@/assets/mini-cart-icon-black-new.svg">
              <img src="@/assets/mini-cart-icon-black-new.svg">
              <img src="@/assets/mini-cart-icon-black-new.svg">
            </div>
            {{ $t('orders.title') }}
          </h2>
        </div>
      </div>
      <div v-if="false" class="row">
        <div class="col-12 offset-lg-2 col-lg-8">
          <p>{{ $t('orders.help') }}</p>
        </div>
      </div>
      <div class="commande-content">
        <div v-if="false" class="row">
          <div class="col-12 offset-lg-2 col-lg-8">
            <p>{{ $t('orders.warning') }}</p>
          </div>
        </div>
        <div class="commande-liste row">
          <div class="col-12 offset-lg-1 col-lg-10">
            <div class="commande-headers d-none d-md-flex row">
              <div class="col-4">{{ $t('orders.deliveryDate') }}</div>
              <div class="col-2 text-center">
                {{ $t('orders.total') }}
              </div>
              <div class="col-3 text-center">
                {{ $t('orders.order') }}
              </div>
              <div class="col-3 text-center">
                {{ $t('orders.invoice') }}
              </div>
            </div>
            <DernieresCommandesItem
              v-for="order in orders"
              :key="order.id"
              :order="order"
              class="row pb-5 p-md-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DernieresCommandesItem from './DernieresCommandesItem.vue'

export default {
  name: 'DernieresCommandes',
  components: { DernieresCommandesItem },

  data: () => ({
    loading: false,
    orders: []
  }),

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('user/orders', {})
        .then(orders => {
          this.orders = orders
          this.$emit('loaded', this.orders)
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#dernierescommandes {
  //background-color: #BA8DC0;
  //color: white;
  text-align: left;
  position: relative;
  padding-top: 150px;
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    width: 70%;
    margin: 0 auto;
  }
}
#dernierescommandes .title {
  padding: 0 40px;
}

#dernierescommandes h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: inherit;
  text-align: center;
  margin-top: 0;
  position: relative;
  @include media-breakpoint-up(md) {
    font-size: 40px;
    padding-bottom: 20px;
  }
}
.cart-icons {
  position: absolute;
  top: -70px;
  left: calc(50vw - 125px);
  @include media-breakpoint-up(md) {
    left: calc(50vw - 135px);
  }
  @include media-breakpoint-up(lg) {
    top: 0;
    left: -150px;
  }
}
#dernierescommandes p {
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 1.8rem;
}
.commande-content {
  background-color: #BA8DC0;
  color: white;
  padding: 30px 20px;

  @include media-breakpoint-up(md) {
    padding: 60px 20px;
  }
}
.commande-liste {
  @include media-breakpoint-up(md) {
    padding-top: 30px;
  }
}
.commande-headers {
  font-weight: 900;
  font-size: 15px;
  padding-bottom: 15px;
}
</style>
