<template>
  <form
    class="infoperso-coord"
    @submit.stop.prevent="submit"
  >
    <div class="row submit-button mb-3">
      <div class="col-12 offset-md-3 col-md-9">
        <label />
        <button type="button" @click="copyCoordinate">
          {{ $t('account.copyAddress') }}
        </button>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="date-of-birth">{{ $t('user.fullname') }}</label>
      </div>
      <div class="col-4 col-md-3 right-padding">
        <UserCourtesyField v-model="$v.user.deliveryCoordinate.courtesyTitle.$model" :rules="$v.user.deliveryCoordinate.courtesyTitle" />
      </div>
      <div class="col-8 col-md-3 left-padding">
        <UserNameField v-model="$v.user.deliveryCoordinate.firstname.$model" :rules="$v.user.deliveryCoordinate.firstname" name="firstname" :placeholder="$t('user.firstname')" />
      </div>
      <div class="offset-4 col-8 offset-md-0 col-md-3 left-padding">
        <UserNameField v-model="$v.user.deliveryCoordinate.lastname.$model" :rules="$v.user.deliveryCoordinate.lastname" name="lastname" :placeholder="$t('user.lastname')" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="address">{{ $t('user.street') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <UserStreetField v-model="$v.user.deliveryCoordinate.street.$model" :rules="$v.user.deliveryCoordinate.street" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="ville">{{ $t('user.cityGroup') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <UserZipField v-model="$v.user.deliveryCoordinate.zip.$model" :rules="$v.user.deliveryCoordinate.zip" :city.sync="$v.user.deliveryCoordinate.city.$model" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="pais">{{ $t('user.country') }}</label>
      </div>
      <div class="col-8 col-md-6">
        <UserCountryField v-model="$v.user.deliveryCoordinate.pays_id.$model" :rules="$v.user.deliveryCoordinate.pays_id" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="address">{{ $t('user.phone') }}</label>
      </div>
      <div class="col-8 col-md-6">
        <UserPhoneField v-model="$v.user.deliveryCoordinate.phone.$model" :rules="$v.user.deliveryCoordinate.phone" />
      </div>
    </div>
    <div class="row submit-button">
      <div class="col-12 offset-md-3 col-md-2">
        <label />
        <button type="submit" :disabled="disabled">
          {{ $t('user.submit') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import UserCourtesyField from '@/components/User/UserCourtesyField'
import UserNameField from '@/components/User/UserNameField'
import UserCountryField from '@/components/User/UserCountryField'
import UserZipField from '@/components/User/UserZipField'
import UserStreetField from '@/components/User/UserStreetField'
import UserPhoneField from '@/components/User/UserPhoneField'
import { required } from 'vuelidate/lib/validators'
import lodash from 'lodash'

export default {
  name: 'InfosPersoAdresseLivraison',
  components: {
    UserNameField,
    UserCourtesyField,
    UserCountryField,
    UserStreetField,
    UserZipField,
    UserPhoneField
  },

  data: () => ({
    loading: false,
    user: {
      lastname: null,
      deliveryCoordinate: {
        courtesyTitle: null,
        firstname: null,
        lastname: null,
        street: null,
        city: null,
        zip: null,
        pays_id: null,
        phone: null
      }
    }
  }),

  validations: {
    user: {
      deliveryCoordinate: {
        courtesyTitle: { required },
        firstname: { required },
        lastname: { required },
        street: { required },
        city: { required },
        zip: { required },
        pays_id: { required },
        phone: { required }
      }
    }
  },

  computed: {
    disabled() {
      return this.loading || this.$v.$invalid
    }
  },

  mounted() {
    this.user = lodash.cloneDeep(lodash.pick(
      this.$store.getters['auth/user'],
      Object.keys(this.user)
    ))
  },

  methods: {
    copyCoordinate() {
      const user = this.$store.getters['auth/user']
      this.user.deliveryCoordinate = lodash.cloneDeep(user.coordinate)
      this.user.deliveryCoordinate.pays_id = 'CH'
      this.user.deliveryCoordinate.courtesyTitle = user.courtesyTitle
      this.user.deliveryCoordinate.firstname = user.firstname
      this.user.deliveryCoordinate.lastname = user.lastname
    },

    submit() {
      if (this.disabled) {
        return
      }
      this.loading = true
      return this.$store.dispatch('auth/updateUser', { user: this.user })
        .then(res => (this.$success(res, { message: 'user.saved' })))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
