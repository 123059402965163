<template>
  <div>
    <div class="d-md-none">
      <SideWidgetMobile />
    </div>
    <div class="d-none d-md-flex">
      <SideWidget />
    </div>
    <MonEspacePerso />

    <a id="orders"></a>
    <DernieresCommandes @loaded="onOrdersLoaded" />

    <a id="account"></a>
    <InfosPerso />

    <div class="container mesfavoris-btn">
      <router-link v-if="partnerFavourite" :to="{ name: 'favourite', params: { partnerId: partnerFavourite } }">
        {{ $t('espacePerso.favourite') }}
        <img src="@/assets/favoris-icon-white-empty.svg" />
      </router-link>
    </div>
  </div>
</template>

<script>
import SideWidgetMobile from '@/components/Layout/SideWidgetMobile.vue'
import SideWidget from '@/components/Layout/SideWidget.vue'
import MonEspacePerso from '@/components/EspacePerso/MonEspacePerso.vue'
import DernieresCommandes from '@/components/EspacePerso/DernieresCommandes.vue'
import InfosPerso from '@/components/EspacePerso/InfosPerso.vue'

export default {
  name: 'ViewEspacePerso',
  components: {
    SideWidgetMobile,
    SideWidget,
    MonEspacePerso,
    DernieresCommandes,
    InfosPerso
  },
  data: () => ({
    partnerFavourite: null
  }),
  methods: {
    onOrdersLoaded(orders) {
      if (orders.length) {
        this.partnerFavourite = orders[0].partner_id
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss">
@import '../assets/styles/youpaq.scss';

.mesfavoris-btn {
  padding-top: 100px;
  padding-bottom: 200px;
  a {
    background-color: var(--red-color);;
    border: none;
    padding: 20px 30px;
    border-radius: 10px;
    color: white;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    box-shadow: 1px 1px 5px #00000087;
    margin-bottom: 150px;
  }
  img {
    width:30px;
  }
}
</style>
