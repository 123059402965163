<template>
  <form
    class="infoperso-auth"
    @submit.stop.prevent="submit"
  >
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="email">{{ $t('accountCreate.email') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <UserEmailField :value="email" readonly />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <label for="email">{{ $t('account.passwordNow') }}</label>
      </div>
      <div class="col-12 col-md-9">
        <UserPasswordField v-model="$v.user.passwordCurrent.$model" :rules="$v.user.passwordCurrent" disable-entropy :placeholder="$t('account.passwordNow')" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <label for="password">{{ $t('account.passwordNew') }}</label>
      </div>
      <div class="pass-input col-12 col-md-4">
        <UserPasswordField v-model="$v.user.password.$model" :rules="$v.user.password" :entropy.sync="passwordEntropy" :placeholder="$t('account.passwordNew')" />
      </div>
      <div class="pass-input col-12 col-md-5">
        <UserPasswordField v-model="$v.user.passwordVerification.$model" :rules="$v.user.passwordVerification" disable-entropy :placeholder="$t('accountCreate.passwordConfirm')" />
      </div>
    </div>
    <div class="row submit-button mt-4">
      <div class="col-12 offset-md-3 col-md-2">
        <label />
        <button type="submit">
          {{ $t('user.submit') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import UserEmailField from '@/components/User/UserEmailField'
import UserPasswordField from '@/components/User/UserPasswordField'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'InfosPersoAuth',
  components: {
    UserEmailField,
    UserPasswordField
  },

  data: () => ({
    loading: false,
    email: null,
    passwordEntropy: 0,
    user: {
      lastname: null,
      password: null,
      passwordVerification: null,
      passwordCurrent: null
    }
  }),

  validations: {
    user: {
      passwordCurrent: {
        required
      },
      password: {
        required,
        minLength: minLength(8),
        goodPasswordEntropy(value) {
          if (value === '') {
            return true
          }
          return this.passwordEntropy >= this.$config.passwordEntropy
        }
      },
      passwordVerification: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },

  computed: {
    disabled() {
      return this.loading || this.$v.$invalid
    }
  },

  mounted() {
    const user = this.$store.getters['auth/user']
    this.email = user.email
    this.user.lastname = user.lastname
  },

  methods: {
    submit() {
      if (this.disabled) {
        return
      }
      this.loading = true
      return this.$store.dispatch('auth/updateUser', { user: this.user })
        .then(res => this.$success(res, { message: 'user.saved' }))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
